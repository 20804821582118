
<div *ngIf="showLoader">
 
<!-- <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isLoading | async" 
class="overlay">


    <mat-spinner   diameter="60"></mat-spinner>

 
</div> -->

</div>