import { Component, OnInit, Inject, OnDestroy, NgZone } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Constant } from 'src/app/constants/constant';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ViewEncapsulation } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { response } from 'express';

import { interval } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CookieService } from 'src/app/services/cookie.service';
import { comparisonValidator, conditionalValidator, isNumeric } from '../utils/calsoft-validators';
import { CustomValidators } from '../utils/custom-validators';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { NewsletterAdsComponent } from 'src/app/theme/components/newsletter-ads/newsletter-ads.component';
declare const gapi: any;
import { TwitterAuthProvider } from 'firebase/auth';
import { TrackingService } from 'src/app/services/tracking.service';
import { ContactService } from 'src/app/services/contact.service';

@Component({

  
  selector: 'calsoft-SignIn',
  templateUrl: './CommonSignIn.component.html',
  styleUrls: ['./CommonSignIn.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CommonSignInComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  registerForm: UntypedFormGroup;
  disable: boolean;
  data: any;
  public serverPath = environment.commonImageApi + "logo/";
  type: any;
  forgotPasswordForm: UntypedFormGroup;
  blurfunction: boolean;
  tokendisable: boolean;
  isPopoverOpen = false;
  private _unsubscribeAll: Subject<any>;
  inc: any = 0;
  public siteName = Constant.siteName
  title: any;
  otpEnable: boolean;
  otpDigit: any;
  otpForm: UntypedFormGroup;
  countdown: number;
  countdownMapping: { '=1': string; other: string; };
  disabled: boolean = false;
  oldValue: any = null;
  googleLoginEnabled: boolean = false;
  text: string;
  forgotPasswordEnabled: boolean=false;
  passwordValidationRequired: any;
  passwordMinimumCharacters: any;
  emailVerificationEnabled: any=0;
  smsVerification: any=0;
  change: boolean=false;
  display: boolean;
  b2bPricingEnabled: any=0;
  referEarnEnable: any=0;
  typeFormEnableHomePage: any=0;
  twitterLoginEnabled: boolean = false;
  referralType: any=0;
  referralPopupForm: any;
  referralLoginTitle: any;
  init: boolean = false;
  submitText: string;
  submitbtn:string='Login';
  constructor(
    private dialogRef: MatDialogRef<CommonSignInComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public userService: UserService,
    public cartService: CartService,
    public snackBar: MatSnackBar,
    private commonService: CommonService,
    private configService: ConfigServiceService,
    private cartServices: CartService,
    private cookieService: CookieService,
    private zone: NgZone,
    public authService: AuthService,
    public afAuth: AngularFireAuth,
    public trackingService: TrackingService,
    private contactService: ContactService,
  ) {
    this.data = data
    this._unsubscribeAll = new Subject();
    this.countdown = Constant.OTP_TIMER;
    this.countdownMapping = {
      '=1': '# second',
      'other': '# seconds'
    };
  }

  ngOnInit() {
    if (window.innerWidth < 822) {
      this.display = false;
    }else{
      this.display=true
    }
    this.text = 'Create User';
    this.title = "Enter Email";
    this.otpEnable = false;
    this.getConfigData();
    this.loginForm = this.formBuilder.group({
      'username': ['', [Validators.required]],
      'password': ['', Validators.compose([Validators.required])]
    });

    this.otpForm = this.formBuilder.group({
      'otp': ['', [Validators.required]],

    });


    this.submitText = 'Submit';

    this.type = 'signup';
    this.disable = false;
    this.blurfunction = true;
    this.tokendisable = false;


  }



  initRegisterForm(){
    this.passwordMinimumCharacters=this.passwordValidationRequired==1?8:this.passwordMinimumCharacters
    this.registerForm = this.formBuilder.group({
      'firstName': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      'lastName': ['', Validators.compose([Validators.required, Validators.maxLength(25)])],
      'email': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      'telephone': ['', [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]*$")]],

      //  'email': ['', Validators.compose([Validators.required, emailValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
      // 'password': ['', ([Validators.required,
      // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      // ])],
      'password': [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          ),

          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          ),
          // check whether the entered password has a lower case letter
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          ),
          // check whether the entered password has a special character
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          ),

          Validators.minLength(this.passwordMinimumCharacters)

        ])
      ],
      'gender': new UntypedFormControl(1),
      'type':[''],


      'confirmPassword': ['', Validators.required],
    }, { validator: comparisonValidator.mustMatch('password', 'confirmPassword') });

  

  }


  initForgotPasswordForm(){
    this.passwordMinimumCharacters=this.passwordValidationRequired==1?8:this.passwordMinimumCharacters
    this.forgotPasswordForm = this.formBuilder.group({
      'email': ['', [Validators.required]],
      'token': ['', [Validators.required]],
      'password': [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          ),

          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          ),
          // check whether the entered password has a lower case letter
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          ),
          // check whether the entered password has a special character
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          ),

          Validators.minLength(this.passwordMinimumCharacters)

        ])
      ],
      'confirmPassword': ['', Validators.required],
    }, { validator: comparisonValidator.mustMatch('password', 'confirmPassword') }

    );
  }
  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          localStorage.setItem(Constant['GUEST_LOGIN'],data['guestLogin'])
          this.commonService.setCurrencyValue(data['currencyBase'])
          this.passwordValidationRequired=data['passwordValidationRequired'];

          this.passwordMinimumCharacters=data['passwordMinimumCharacters'];
          this.passwordMinimumCharacters=this.passwordValidationRequired==1?8:this.passwordMinimumCharacters
          this.initRegisterForm();
          this.initForgotPasswordForm();
          this.emailVerificationEnabled=data['emailVerification'];
          this.referEarnEnable=data['referEarnEnable'];
          this.smsVerification=data['smsVerification'];
          this.referralType=data['referralType'];
          this.referralPopupForm=data['referralPopupForm'];
          this.referralLoginTitle=data['referralLoginTitle'];
          
          if (data['otpEnabled'] == 1) {

            this.otpEnable = true;
            this.title = "Enter Email/Mobile Number";
            this.otpDigit = data['otpMaxDigit']
          } else {
            this.otpEnable = false;
            this.title = "Enter Email/Mobile Number";
          }


         this.b2bPricingEnabled=data['b2bPricingEnabled']

          if (data['googleSocialLoginStatus']) {
            this.googleLoginEnabled = true;
            //this.initialize(data['googleSocialLogin']);
          }


          if (data['twitterLoginStatus']) {
            this.twitterLoginEnabled = true;
          }

          if (data.typeFormEnableHomePage != undefined) {
            this.typeFormEnableHomePage = data.typeFormEnableHomePage;

         } else {
            this.typeFormEnableHomePage = 0;
         }




        }, error => {

        }
      );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public onLoginFormSubmit(values: Object): void {
    if (this.loginForm.valid) {
      this.router.navigate(['/']);
    }
  }

  onBlurMethod(param) {
    if (param == 'forgotPassword' && this.forgotPasswordForm.controls.email.status != 'INVALID' && this.blurfunction == true) {
      //  this.forgotPassword();
    }
    else if (this.registerForm.controls.email.status != 'INVALID') {
      this.emailVerified();

    }
  }

  forgotPassword(userName, param,type) {
    this.userService.forgotPassword(userName).subscribe(
      (response) => {
        if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
          this.blurfunction = false;
          this.forgotPasswordEnabled=true;
          this.registerForm.reset();
          this.forgotPasswordForm.reset();

          this.loginForm.controls.password.reset();
          this.forgotPasswordForm.value.email = this.loginForm.value.username
          this.forgotPasswordForm.patchValue(this.forgotPasswordForm.value)
          if(type=='refresh'){
            this.snackBar.open("Verification code sent to"+" "+this.loginForm.value.username , '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 5000 });
          }
          else{
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 5000 });
            this.type = param;
          }

        } else {

          if(response['status_message']=="User PassWord Can't be Changed. Please Contact System Admin!"){
            this.loginForm.controls.username.setErrors({ userDoesNotChanged: true })
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 5000 });
          }else{
            this.loginForm.controls.username.setErrors({ userDoesNotExist: true })
            this.snackBar.open('You are not registered with us. Please sign up.', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 5000 });
          }
          this.forgotPasswordEnabled=false;
            this.blurfunction = false;

        }
      },
      (err) => { });
  }

  emailVerified() {

    if (this.registerForm.valid) {
      this.text = 'Processing';
      this.userService.emailVerified(this.registerForm.value.email).subscribe(
        (response) => {
          if (response['data']) {
            this.text = 'Create User';
            this.registerForm.controls.email.setErrors({ mismatchedPasswords: true })
          } else {
            this.userService.customerNumberCheck(this.registerForm.value.telephone).subscribe(
              response => {

                if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
                  this.text = 'Create User';
                  this.registerForm.controls.telephone.setErrors({ numberExists: true })

                } else {
                  this.createUser(this.registerForm.value)
                }



              },
              (err) => { this.text = 'Create User'; });


          }
        },
        (err) => { this.text = 'Create User'; });
    } else {
      this.text = 'Create User';
      this.registerForm.markAllAsTouched();

    }
  }

  tokenVerify() {
    if (this.forgotPasswordForm.controls.email.status != 'INVALID' && this.forgotPasswordForm.controls.token.status != 'INVALID') {
      this.userService.verifyForgotPasswordToken(this.forgotPasswordForm.value).subscribe(
        (response) => {
          if (response['data'] == false) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 5000 });
            this.forgotPasswordForm.controls.token.setErrors({ invalidToken: true })
            this.disable = false;
          } else {
            this.disable = true;
            this.tokendisable = true;
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 5000 });
          }
        },
        (err) => { });
    }
  }

  changePassword(values: Object) {
    this.userService.changePassword(this.forgotPasswordForm.value).subscribe(
      res => {
        if (res['data'] == false) {
          this.snackBar.open(res['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 5000 });
        }
        else {
          this.snackBar.open(res['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 5000 });
          this.dialogRef.close({ event: 'close' })
        }

      })

  }


  genders = [
    { value: 1, viewValue: "Male" },
    { value: 0, viewValue: "Female" },
    { value: 2, viewValue: "Not Specified" },
  ]


  createUser(values: Object) {

    this.registerForm.value.gender = 1;
    if (this.registerForm.valid) {
      this.registerForm.value.dob = "2015-08-25 10:29:26"
      this.registerForm.value.operationType = "New";
      this.userService.createCustomers(this.registerForm.value).subscribe(
        response => {

         
          // this.toolbarComponent.ngOnInit();
          if(this.emailVerificationEnabled==1&&this.smsVerification==1){
            this.snackBar.open("User Verfication code sent to your mail and mobile please enter code to verify", '×', { panelClass: 'success', verticalPosition: 'top', duration: 10000 });
            this.type='otp'
            this.loginForm.value.username=this.registerForm.value.telephone;
            this.loginForm.patchValue(this.loginForm.value)
            this.change=true;
          }else  if(this.emailVerificationEnabled==1&&this.smsVerification==0){
            this.snackBar.open("User Verfication code sent to your mail please enter code to verify", '×', { panelClass: 'success', verticalPosition: 'top', duration: 10000 });
            this.type='otp'
            this.loginForm.value.username=this.registerForm.value.telephone;
            this.loginForm.patchValue(this.loginForm.value)
            this.change=true;
          }else  if(this.emailVerificationEnabled==0&&this.smsVerification==1){
            this.snackBar.open("User Verfication code sent to your mobile please enter code to verify", '×', { panelClass: 'success', verticalPosition: 'top', duration: 10000 });
            this.type='otp'
            this.loginForm.value.username=this.registerForm.value.telephone;
            this.loginForm.patchValue(this.loginForm.value)
            this.change=true;
          }
          else{
            this.snackBar.open("User registered SuccessFully", '×', { panelClass: 'success', verticalPosition: 'top', duration: 10000 });
            this.dialogRef.close({ event: 'close' })
          }
         
          this.text = 'Create User';
        },
        
        (err) => {   this.text = 'Create User'; });
    } else {
      this.text = 'Create User';
      this.registerForm.markAllAsTouched();

    }

  }


  referralLoginCustomer(){
    if(this.referralType==0){
      this.referAndEarn();
    }else{
      this.createDynamicForm();
    }
  }



  regConfig: any[] = [];
  
    createDynamicForm() {
        this.referEarnEnable=1;
        this.type = "refer";
        this.contactService.getDynmaicForm(this.referralPopupForm).subscribe((data) => {
        let value: any = data
        this.regConfig = value;
        this.init = true;
      });
    }
  
 

  createUserValidations = {
    'password': [
      { type: 'required', message: 'Please fill out this field.' },
    ],
    'username': [
      { type: 'required', message: 'Please enter valid Email ID/Mobile number' },
      { type: 'wrongPattern', message: 'Please enter Valid Email ID/Mobile number' },
      { type: 'userDoesNotExist', message: 'You are not registered with us. Please sign up.' },
      { type: 'userDoesNotChanged', message: "User PassWord Can't be Changed. Please Contact System Admin!" },

    ],
    'telephone': [
      { type: 'required', message: 'Please fill out this field.' },

      { type: 'minlength', message: 'Please Enter a Valid Mobile Number' },
      { type: 'maxlength', message: 'Please Enter a Valid Mobile Number' },
      { type: 'numberExists', message: 'Number is already registered' },
    ],
    'email': [
      { type: 'required', message: 'Please fill out this field.' },
      { type: 'pattern', message: 'Enter a Valid email format' },
      { type: 'mismatchedPasswords', message: 'User is already registered' },
      { type: 'userDoesNotExist', message: 'User is not registered' },

    ],
    'token': [
      { type: 'required', message: 'Please fill out this field.' },
      { type: 'invalidToken', message: 'Token verification Id is invalid' },

    ],
    'gender': [
      { type: 'required', message: 'Please select this field.' },

    ],
    'firstName': [
      { type: 'required', message: 'Please fill out this field.' },
      { type: 'minlength', message: 'First Name Should Contain Atleast 3 Characters' },

    ],
    'lastName': [
      { type: 'required', message: 'Please fill out this field.' },
      { type: 'minlength', message: 'Last Name Should Contain Atleast 1 Characters' },

    ],
    'otp': [
      { type: 'required', message: 'Please fill out this field.' },


    ],
  }

  login(values: Object) {
    if (this.loginForm.valid) {
      this.submitbtn = 'Processing';

      this.userService.loginFn(this.loginForm.value.username, this.loginForm.value.password).subscribe(
        response => {

          if (response['status_code'] == Constant.USER_INACTIVE_FAILURE) {
           this.exchange('otp')
            }
          else if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            localStorage.removeItem(Constant['CART_ID']);
          localStorage.removeItem(Constant.CART_COUNT)
 
          if(response.data.customer.additional.b2bUser==1&&this.b2bPricingEnabled==1){
            localStorage.setItem(
              Constant.B2B_USER,
              response.data.customer.additional.b2bUser
            );
          }else{
            localStorage.setItem(
              Constant.B2B_USER,
              "0"
            );
          }
        
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.dialogRef.close({ event: 'update' })
            if (this.typeFormEnableHomePage === 1) {                           
              this.typeForm();
          }
            if (this.data.page == 'cart') {
              this.router.navigate([`/${this.data.page}`])
            }
          
            this.getCustomerCount();
            this.gettechid();
            this.userService.reload()
          }
          else if (response['status_code'] == Constant.RESPONSE_FAILURE) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
      );
    }
    else {
      this.validateAllFormFields(this.loginForm);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  getCustomerCount() {
    
    let authourized = this.userService.getAuthenticatedUser();
    if (authourized) {

      this.cartServices.getCustomerCartProductCount();
      this.cartService.geWishListProduct();
    }

  }
  logOut() {
    this.userService.logout();
    localStorage.removeItem(Constant.CART_COUNT)
    this.cartServices.responseCache1 = new Map();

  }

  close() {
    this.dialog.closeAll();
  }
  close1222(){
    this.dialog.closeAll();

  }

  exchange(param) {

    this.referEarnEnable=0;
    if (param == 'otp') {
      if (isNumeric(this.loginForm.value.username)) {

        if (this.loginForm.value.username == Math.floor(this.loginForm.value.username)) {
          if (this.loginForm.value.username.length == this.otpDigit) {
            this.type = param;
            this.loginUsingOtp();
          } else {
            this.loginForm.controls.username.setErrors({ wrongPattern: true })
          }
        } else {
          this.loginForm.controls.username.setErrors({ wrongPattern: true })
        }
      } else {
        this.loginForm.controls.username.setErrors({ wrongPattern: true })
      }
    } else if (param == 'signup1') {
      this.type = "signup";
    } else if (param == 'registers') {
      this.type = "register";
      this.registerForm.value.telephone = this.loginForm.value.username;
      this.registerForm.patchValue(this.registerForm.value)
      this.loginForm.reset();
    }

    else if(param == 'forgotPassword') {

      if (this.loginForm.controls.username.status != "INVALID") {

       // console.log("sas");
        
       this.forgotPasswordEnabled=true;
         // this.forgotPassword(this.loginForm.value.username, param,"user")
        
       

      } else {

      }

    }
    else {
      this.loginForm.reset();
      this.registerForm.reset();
      this.forgotPasswordForm.reset();
      this.type = param;
    }

    this.tokendisable=true;
    this.blurfunction=false;
    this.disable=false;

  }

  enterFunc() {
    let i;
    if (this.type == 'signup') {
      this.login(i);
      event.preventDefault();
    }
    else {

      event.preventDefault();

    }
  }

  changeUserName() {

    this.forgotPasswordForm.reset();

    this.forgotPasswordEnabled=false;

    this.loginForm.reset();

    this.type="signup"


  }


  gotoCorresspondingPage(url) {
    this.dialog.closeAll();
    this.router.navigate([`/${url}`])
  }

  loginUsingOtp() {


    this.userService.loginUsingOtp(this.loginForm.value.username).subscribe(
      response => {

    
        if (response['status_code'] == Constant.USER_FAILURE) {

          this.exchange('registers')
          this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
        }
        if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
          localStorage.removeItem(Constant['CART_ID']);
          localStorage.removeItem(Constant.CART_COUNT)
          this.disabled = true;

          this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });

          this.startTimer();
        }
        else if (response['status_code'] == Constant.RESPONSE_FAILURE) {
          this.exchange('signup')
          this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }
      },
    );

  }

  startTimer() {
    this.disabled = true;
    const duration = this.countdown;
    interval(1000)
      .pipe(
        take(duration),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.countdown--;
      },
        () => {
        },
        () => {
          this.disabled = false;
        }
      );
  }

  verifyOpt() {
    if (this.otpForm.valid) {
      this.userService.verifyOtp(this.loginForm.value.username, this.otpForm.value.otp).subscribe(
        response => {

          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.dialogRef.close({ event: 'update' })
            if (this.data.page == 'cart') {
              this.router.navigate([`/${this.data.page}`])
            }
            if (this.router.url.includes('/product?pid')) {

              this.commonService.productNavigationReload(this.router.url);
            }
            this.getCustomerCount();

          }
          else if (response['status_code'] == Constant.RESPONSE_FAILURE) {
            this.snackBar.open("OTP is incorrect", '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
      );
    } else {
      this.otpForm.markAllAsTouched();
    }
  }

  resendOtp() {
    this.userService.resendOtp(this.loginForm.value.username).subscribe(
      response => {
        this.countdown = Constant.OTP_TIMER;

        if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
          this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
          this.startTimer();
        }
        else if (response['status_code'] == Constant.RESPONSE_FAILURE) {
          this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }
      },
    );

  }

  checkPhoneNumberAlreadyExist() {


    if (this.oldValue != this.registerForm.value.telephone) {
      this.userService.customerNumberCheck(this.registerForm.value.telephone).subscribe(
        response => {

          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {

            this.registerForm.controls.telephone.setErrors({ numberExists: true })

          }

          this.oldValue = this.registerForm.value.telephone;

        },
      );
    }


  }

  public auth2: any;
  // initialize(clientId) {
  //   //  document.getElementById('googleBtn').remove();
  //   this.auth2 = null;

  //   gapi.load('auth2', () => {

  //     this.auth2 = gapi.auth2.init({
  //       client_id: clientId,

  //     });
  //     this.attachSignin();
  //   });
  // }

  //   ngAfterViewInit(){
  //     this.initialize();
  // }


  socialloginGoogle(){
    let result=this.GoogleAuth();
  }


  TwitterLogin(){
    let result1=this.TwitterAuth();
  }


  TwitterAuth() {
    return this.AuthLoginTwitter(new TwitterAuthProvider());
  }

  socialLogin(values) {

    values=values['additionalUserInfo']['profile']

    let firstName = values['name'];

    let email = values['email'];
    let lastName = values['family_name']
    
    this.userService.socialLogin(email, firstName, lastName).subscribe(
      response => {
        this.zone.run(() => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.dialogRef.close({ event: 'update' })
            if (this.data.page == 'cart') {
              this.router.navigate([`/${this.data.page}`])
            }
            if (this.router.url.includes('/product?pid')) {

              this.commonService.productNavigationReload(this.router.url);
            }
            this.getCustomerCount();
           
            this.signOut();
          }
          else if (response['status_code'] == Constant.RESPONSE_FAILURE) {
            this.signOut();
            this.snackBar.open("Wrong Details", '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
          (err) => {
            this.signOut();
            this.snackBar.open("Wrong Details", '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          });
      });
  }




  signOut() {
 
    this.zone.run(() => {
      this.auth2.disconnect();
      localStorage.removeItem(Constant['CART_ID']);
          localStorage.removeItem(Constant.CART_COUNT)
      this.auth2.signOut();
    });
       let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])

     if (authourized==null||guestLoginEnabled=="1") {
    this.cartServices.getCount1();
     }
     this.cartServices.responseCache1 = new Map();

  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  checkContains(code,form ){
    let value= this[form].value[code];   
    value=value.replace(/[^0-9]/g, '')
    this[form].value[code] = value;
    this[form].patchValue(this[form].value)
  }

  public attachSignin() {
    this.auth2.attachClickHandler(document.getElementById('googleBtn'), {},
      (googleUser) => {

        let profile = googleUser.getBasicProfile();

      }, (error) => {

      });
  }




  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  AuthLogin(provider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {

           this.socialLogin(result)
    
      })
      .catch((error) => {

         console.log(error);
      });
  }

  AuthLoginTwitter(provider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        console.log(result);
        console.log('You have been successfully logged in!');
      })
      .catch((error) => {
        console.log(error);
      });
  }
  

  referAndEarn(){
    this.referEarnEnable=1;
    this.type = "register";
      this.registerForm.value.telephone = this.loginForm.value.username;
      this.registerForm.patchValue(this.registerForm.value)
      this.loginForm.reset();
      //this.setAutoGeneration();
  }

  setAutoGeneration() {
    this.userService.generateReferalId().subscribe(
      (response) => {
        if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
          this.snackBar.open('Referral Id Generated!', '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
        }
        else {
          this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }
      },
      (err) => {});
  }

  typeForm() {
    if (window.innerWidth > 768) {

       let dialogRef = this.dialog.open(NewsletterAdsComponent, {
          width: '790px',
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {
          //  this.ngOnInit();


       });

    }
    else {
       let dialogRef = this.dialog.open(NewsletterAdsComponent, {
          minWidth: '100vw', height: '100vh',
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {
          //  this.ngOnInit();


       });
    }

 }

 gettechid() {
  this.trackingService.getTechnicianId().subscribe(
    response => {
      if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
        let userId = parseInt(response['data']);
        console.log(userId);
        //localStorage.setItem('techId', userId.toString())
      }
    },
    error => {
      console.error('Error fetching technician ID:', error);
    }
  );
}

submit(value: any) {

  let filteredList = this.regConfig
    .filter(item => item.inputType.toLowerCase().includes('button'))
    .map(item => ({ label: item.label }));

  filteredList.forEach(label => {
    if (value.hasOwnProperty(label.label)) {
      delete value[label.label];
    }
  });

  let filteredListFile = this.regConfig
    .filter(item => item.inputType.toLowerCase().includes('file'))
    .map(item => ({ label: item.name }));

  filteredListFile.forEach(label => {
    if (value.hasOwnProperty(label.label)) {
      const newKey = label.label + '_file';
      value[newKey] = value[label.label];
      delete value[label.label];
    }
  });

  let object = {};
  let keysToCheck = ["phoneNumber", "email", "name"];
  let keysList = keysToCheck.filter(key => value.hasOwnProperty(key));
  keysList.forEach(key => {
    object[key] = value[key];
  });

  object['formGroupId'] = this.referralPopupForm;
  object['value'] = JSON.stringify(value);



  this.saveForm(object)

}


saveForm(object) {
  if (this.submitText == 'Submit') {

    if (object.userName == null) {
      object.userName = 0;
    }

    if (object.telephone == null) {
      object.telephone = 0;
    }

    if (object.password == null) {
      object.password = 0;
    }

    if (object.firstName == null) {
      object.firstName = 0;
    }

    if (object.lastName == null) {
      object.lastName = 0;
    }

    if (object.accountNo == null) {
      object.accountNo = 0;
    }

    if (object.confirmAccountNo == null) {
      object.confirmAccountNo = 0;
    }

    if (object.confirmPassword == null) {
      object.confirmPassword = 0;
    }

    if (object.email == null) {
      object.email = 0;
    }

    if(object.operationType = "New"){
    this.contactService.referralCustomer(object).subscribe(
      (response) => {
        if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
          this.submitText = "Submit";
          this.snackBar.open("User registered SuccessFully", '×', { panelClass: 'success', verticalPosition: 'top', duration: 10000 });
          this.dialog.closeAll();
          this.router.navigate(['/home'])
        }
        else {
          this.submitText = "Submit";
          this.snackBar.open("User registered Failed", '×', { panelClass: 'error', verticalPosition: 'top', duration: 10000 });
        }
      },
      (err) => { this.submitText = "Submit" });
  }
  else {

  }
}
}



}




